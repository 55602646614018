import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import ActivatedProfileIcon from 'Components/icons/ActivatedProfile'
import { Typography, Grid } from '@material-ui/core'
import ActionButtonModal from 'Components/ActionButtonModal'
import { useRouter } from 'next/router'
import useStyles from './style'

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
))

export default function ModalActivatedprofile({ open, onClose }) {
  const classes = useStyles()
  const router = useRouter()
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="confirm-dialog-description"
      className={classes.dialog}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.container}
      >
        <Grid item>
          <ActivatedProfileIcon size={80} />
        </Grid>
        <Grid item>
          <Typography variant="h6" className={classes.title}>
            Perfil Ativo!
          </Typography>
          <Typography variant="h6" className={classes.subTitle}>
            Muito bom ter você de volta
          </Typography>
        </Grid>
        <Grid item xs={10} md={10} sm={10}>
          <Typography className={classes.bodyTitle}>
            Atenção aos convites para entrevista de emprego, eles chegarão por
            Whatsapp e email. Boa sorte, qualquer coisa pode contras com a gente.
          </Typography>
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.content}
        >
          <Grid className={classes.item} item xs={10} md={10} sm={10}>
            <ActionButtonModal
              onClick={() => router.push('/')}
              title="Visualizar meu perfil"
            />
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  )
}
