import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, Dialog, Grid, Paper, Slide, Typography, useTheme, withStyles, } from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help';

import useAuth from 'context/useAuth'
import CongratulationIcon from 'components/icons/Congratulation';
import ScoreStepper from 'components/ScoreStepper';
import { useRouter } from 'next/router';
import {scoreLevels, scoreStepLevels} from 'utils/score'

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: theme.palette.primary.contrastText,
    padding: theme.spacing(2),
  },
  title: {
    fontSize: 25,
    fontWeight: 'bold',
  },
  help: {
    display: 'flex',
    alignItems: 'center',

    "& > svg": {
      marginLeft: 10,
    }
  },
  text: {
    fontSize: 16,
    marginLeft: theme.spacing(4),
  },
  textModal: {
    fontSize: 26,
    textAlign: 'center',
  },
  textGreen: {
    color: theme.palette.success.main,
    fontWeight: 'bold',
  },
  textGreenUnderline: {
    color: theme.palette.success.main,
    fontWeight: 'bold',
    textDecoration: 'underline'
  },
  textBlue: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  textUnderline: {
    textDecoration: 'underline'
  },
  textBold: {
    fontWeight: 'bold',
  },
  header: {
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
      display: 'block',
      textAlign: 'center',
    }
  },
  body: {
    display: 'flex',
    textAlign: 'left',

    [theme.breakpoints.down('sm')]: {
      display: 'block',
      textAlign: 'center',

      '& svg': {
        marginBottom: theme.spacing(2)
      }
    }
  },
  modalTitle: {
    fontSize: 34,
    textAlign: 'center',
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    lineHeight: '110%'
  },
}))

const GreenButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    fontSize: 19,
    fontWeight: 'bold',
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),

    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
}))(Button)

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const stepLevelContinueUrl = (candidate) => {
  if(Number(candidate.detailed_score?.profile?.modules || 0) <= 0){
    return '/provas'
  }

  if(Number(candidate.detailed_score?.profile?.modules || 0) > 0 && Number(candidate.healthscore || 0) < 100){
    return '/editar/cadastro-do-candidato'
  }

  return null
}

function HealthScoreCandidate({score = null}) {
  const theme = useTheme()
  const classes = useStyles(theme)
  const {session} = useAuth()
  const router = useRouter()
  const [open, setOpen] = useState(false)

  const healthscore = score || session.candidate.healthscore || 0
  const level = scoreStepLevels(Number(healthscore))
  const levelText = scoreLevels[level]
  const levelNextText = scoreLevels[level + 1] || null
  const url = stepLevelContinueUrl(session.candidate)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickNext = () => {
    return router.push(url)
  };

  return (
    <Paper className={classes.box}>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        maxWidth="md"
      >
        <Paper>
          <Box p={4}>
            <Typography gutterBottom className={classes.modalTitle}>
              Como aumentar seu nível<br />
              de engajamento
            </Typography>
            <p className={classes.textModal}>
              O <span className={classes.textBlue}>Nível do perfil</span> funciona como uma avaliação de como você usa a nossa plataforma,
              <span className={classes.textUnderline}>quanto mais dados preencher em seu cadastro, mais cursos e provas fizer,
              quanto mais frequentes forem seus acessos e
                quanto mais se tornar participativo</span>, mais alto seu <span className={classes.textBlue}>Nível do perfil</span> se tornará.
              <br/>
              <br/>
              A medida que seu <span className={classes.textBlue}>Nível do perfil</span> fica melhor, você aparece mais nas pesquisas de candidato
              além do que, seu perfil fica mais confiável e passa mais <span className={classes.textGreenUnderline}>credibilidade para as empresas que estão em busca de candidatos</span>.
            </p>
            <Box mt={2} textAlign="right">
              <Button onClick={handleClose} variant="contained" color="secondary">Fechar</Button>
            </Box>
          </Box>
        </Paper>
      </Dialog>
      <Box display="flex" justifyContent="space-between" mb={2} className={classes.header}>
        <Typography variant="h6" color="secondary" className={classes.title}>
          Nível do perfil
        </Typography>
        <Button onClick={handleClickOpen} variant="text">
          <Typography variant="caption" color="primary" className={classes.help}>
            Como consigo aumentar o meu nível?
            <HelpIcon/>
          </Typography>
        </Button>
      </Box>
      <Box mx={8}>
        <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={8}>
          <Box alignItems="center" className={classes.body}>
            <CongratulationIcon/>
            <Box className={classes.text}>
              Parabéns! Você Já chegou no <span className={classes.textGreen}>Nível {levelText}</span>!<br/>
              {!!levelNextText && (<>
                Se você realizar as <span className={classes.textBold}>Provas e preencher o resto do seu perfil</span>,<br/>
                voce chegará no <span className={classes.textBlue}>Nível {levelNextText}</span> e conseguirá mais
                oportunidades de emprego!
              </>)}
              {!levelNextText && (<>
                Seu perfil está completo, continue usando a plataforma para continuar no topo das indicações de vagas
                para as empresas!
              </>)}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box display="flex" alignItems="center" justifyContent="end">
            {!!url && <GreenButton size="large" variant="contained" onClick={handleClickNext}>CONTINUAR</GreenButton>}
          </Box>
        </Grid>
      </Grid>
      </Box>
      <Box mt={2}>
        <ScoreStepper activeStep={level}/>
      </Box>
    </Paper>
  )
}

export default HealthScoreCandidate
