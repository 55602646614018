import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { Avatar, Box, Button, CircularProgress, Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { IoIosPeople } from 'react-icons/io'
import { red } from '@material-ui/core/colors';
import { DateTime } from 'luxon';
import { useCompany } from 'hooks/useCompany';
import { isLastItem } from '../../providers/utils';



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
  },
  title: {
    fontFamily: "'Exo', sans-serif",
    fontWeight: 600,
    letterSpacing: 3,
    marginBottom: 45
  },
  titleIcon: {
    color: theme.palette.primary.light,
    fontSize: 76,
  },
  iconButton: {
    border: '2px solid #444444',
    color: '#444444',

    '&.Mui-disabled': {
      border: '2px solid rgba(0, 0, 0, 0.26)',
    }
  },
  avatarBox: {
    display: 'flex',

    [theme.breakpoints.down('md')]: {
      width: '100%',
      display: 'block',
    }
  },
  avatar: {
    border: `1px solid ${theme.palette.primary.light}`,
    width: 145,
    height: 145,
    marginRight: 15,

    [theme.breakpoints.down('md')]: {
      margin: 'auto',
    }
  },
  opportunityName: {
    color: theme.palette.primary.light,
  },
  boxInfos: {
    width: 'calc(100% - 160px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    textAlign: 'left',
    fontFamily: "'Exo', sans-serif",

    [theme.breakpoints.down('md')]: {
      width: '100%',
      display: 'block',
    }
  },
  name: {
    width: '100%',
    color: 'black',
    fontSize: 34,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 300,
    marginBottom: 10,
    lineHeight: '40px',
  },
  infos: {
    width: '100%',
    color: '#BBB',
    fontSize: 24,
    fontWeight: 300,
  },
  containerCandidateFlex: {
    display: 'flex',
    marginBottom: 35,
  },
  containerCandidate: {
    display: 'flex',
    maxWidth: '100%',

    [theme.breakpoints.down('md')]: {
      width: 'auto',
      display: 'block'
    }
  },
  buttons: {
    marginBottom: 45
  },
  hired: {
    padding: '20px 5px',
    fontSize: 20,
    color: 'white',
    backgroundColor: theme.palette.success.light,

    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    }
  },
  reproved: {
    padding: '20px 5px',
    fontSize: 20,
    color: 'white',
    backgroundColor: red[500],

    '&:hover': {
      backgroundColor: red[800],
    }
  },
  notAttend: {
    padding: '20px 5px',
    fontSize: 20,
    color: 'white',
    backgroundColor: theme.palette.primary.light,

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    }
  },
}));

export default function ModalInterviewResult({ interviews, open, setCompanyInterviews }) {

  const theme = useTheme()
  const classes = useStyles(theme)
  const [interviewIndex, setInterviewIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const {
    hireEmployee,
    absentCandidate,
    rejectCandidate,
  } = useCompany();

  const canGoNext = (interviewIndex + 1) < interviews?.length
  const canGoPrev = interviewIndex > 0
  const interview = interviews[interviewIndex]
  interview.candidate.image = interview.candidate.avatar

  function handleNext() {
    if (!canGoNext) return

    setInterviewIndex(interviewIndex + 1)
  }

  function handlePrev() {
    if (!canGoPrev) return

    setInterviewIndex(interviewIndex - 1)
  }

  function resetInterviews() {
    setInterviewIndex(0)
    setCompanyInterviews(interviews.filter(i => i.id !== interview.id))
  }

  function handleHired() {
    setLoading(true)

    hireEmployee(interview.id, {
      subsidiary: interview.opportunity.subsidiary.id,
      opportunity: interview.opportunity.id,
      people: interview.candidate.id,
      job_type: interview.opportunity.type_opportunity,
      status: 'accepted',
      is_activate: true,
    })
      .then((data) => {
        resetInterviews()
      })
      .catch(console.error)
      .finally(() => setLoading(false))
  }

  function handleAbsent() {
    setLoading(true)

    absentCandidate(interview.id, interview.candidate.id)
      .then((data) => {
        resetInterviews()
      })
      .catch(console.error)
      .finally(() => setLoading(false))
  }

  function handleRejected() {
    setLoading(true)

    rejectCandidate(interview.id, interview.candidate.id)
      .then((data) => {
        resetInterviews()
      })
      .catch(console.error)
      .finally(() => setLoading(false))
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogContent className={classes.root}>
        <Box className={classes.titleIcon}>
          <IoIosPeople color="inherit" />
        </Box>
        <Typography variant="h5" color="primary" className={classes.title} gutterBottom>
          RESULTADO DA ENTREVISTA
          <Box className={classes.opportunityName}>{interview.opportunity.name} - {interview.opportunity.position}</Box>
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={2}>
            <IconButton disabled={!canGoPrev} className={classes.iconButton} onClick={handlePrev}>
              <ChevronLeftIcon fontSize="medium" color="inherit" />
            </IconButton>
          </Grid>
          <Grid item xs={8} justifyContent="center" className={classes.containerCandidateFlex}>
            <Box className={classes.containerCandidate}>
              <Box className={classes.avatarBox}>
                <Avatar src={interview.candidate.image} className={classes.avatar} />
              </Box>
              <Box className={classes.boxInfos}>
                <Box className={classes.name}>
                  {interview.candidate.name}
                </Box>
                <Box className={classes.infos}>
                  {`${interview.candidate.years_old} anos`}<br/>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <IconButton disabled={!canGoNext} className={classes.iconButton} onClick={handleNext}>
              <ChevronRightIcon fontSize="medium" color="inherit" />
            </IconButton>
          </Grid>
          <Grid container spacing={2}>
            <Grid xs={2} />
            <Grid xs={8}>
              {!loading && <Grid container spacing={2} className={classes.buttons}>
                <Grid item xs={12} md={4}>
                  <Button className={classes.hired} size="large" fullWidth variant="contained" onClick={handleHired}>
                    Contratado(a)
                  </Button>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button className={classes.reproved} size="large" fullWidth variant="contained" onClick={handleRejected}>
                    Reprovado(a)
                  </Button>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button className={classes.notAttend} size="large" fullWidth variant="contained" onClick={handleAbsent}>
                    Não compareceu
                  </Button>
                </Grid>
              </Grid>}
              {!!loading && <CircularProgress className={classes.buttons} />}
            </Grid>

            <Grid xs={2} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

