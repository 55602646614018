import React, { useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import UnresolvedProfileIcon from 'Components/icons/UnresolvedProfile'
import ExhaustedIcon from 'Components/icons/Exhausted'
import { Typography, Grid } from '@material-ui/core'
import ActionButtonModal from 'Components/ActionButtonModal'
import ModalActivatedprofile from 'Components/ModalActivatedProfile'
import ModalDisableFeedback from 'Components/ModalDisableFeedback'
import { service } from 'services'
import { useRouter } from 'next/router'
import useSnackbar from 'context/useSnackbar'
import useStyles from './style'

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
))

export default function ModalWarningInterview({
  open, onClose, name, qtdInterview, openInterviews,
}) {
  const classes = useStyles()
  const router = useRouter()
  const [openModalActivatedProfile, setOpenModalActivatedProfile] = useState(false)
  const [openModalDisableFeedback, setOpenModalDisableFeedback] = useState(false)
  const [openModalWarning, setOpenWarning] = useState(open)
  const { updateSnackbar } = useSnackbar()

  const [reasons, setReasons] = useState([])

  const handleClose = () => {
    setOpenWarning(false)
  }

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={openModalWarning}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="confirm-dialog-description"
      className={classes.dialog}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.container}
      >
        <Grid item>
          <ExhaustedIcon />
        </Grid>
        <Grid item>
          <Typography variant="h5" className={classes.title}>
            Atenção
          </Typography>
          <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
          >
            <Typography variant="h5" className={classes.subTitle}>
              Você recebeu
            </Typography>
            <div style={{
              background: '#35BFEE', margin: '10px', fontWeight: 'bold', fontSizE: '18px', width: '28px', height: '28px', color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center',
            }}
            >{qtdInterview}
            </div>
            <Typography variant="h5" className={classes.subTitle}>
              convites
            </Typography>
          </div>
          <Typography variant="h5" className={classes.subTitle}>
            para entrevista de emprego.
          </Typography>
        </Grid>
        <Grid item xs={10} md={10} sm={10}>
          <Typography className={classes.bodyTitle}>
            É muito importante que você responda os chamados de entrevistas, para
            que seu perfil não seja desativado.
          </Typography>
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.content}
        >
          <Grid className={classes.item} item xs={10} md={10} sm={10}>
            <ActionButtonModal
              onClick={() => {
                handleClose()
                openInterviews()
              }}
              title="Visualizar convites de entrevistas"
            />
          </Grid>
          <Grid container direction="row" className={classes.containerLink}>
            <Typography style={{ fontSize: '14px' }}>
              Caso esteja com dificuldades ou duvidas,
            </Typography>
            <a className={classes.link} href="https://worc.com.br/perguntas-frequentes" rel="noopener noreferrer" target="_blank">
              clique aqui
            </a>
          </Grid>
        </Grid>
      </Grid>
      <ModalActivatedprofile open={openModalActivatedProfile} />
      <ModalDisableFeedback
        reasons={reasons}
        open={openModalDisableFeedback}
        onClose={() => handleBack()}
      />
    </Dialog>
  )
}
