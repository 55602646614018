import React, { useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import {
  Avatar,
  Box,
  Button,
  Chip,
  fade,
  Grid,
  IconButton,
  TextField,
  Typography,
  useTheme,
  withStyles,
  useMediaQuery,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import InterviewsIcon from 'components/icons/Interviews'
import Modal from '@material-ui/core/Modal'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import CloseIcon from '@material-ui/icons/Close'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import { DateTime } from 'luxon'
import { currencyFormat } from 'providers/utils'
import { Autocomplete } from '@material-ui/lab'

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />)

const RedButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    fontSize: 28,

    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}))(Button)

const GreenButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    fontSize: 28,

    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
    '&:disabled': {
      backgroundColor: fade(theme.palette.success.main, 0.5),
    },
  },
}))(Button)

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  control: {
    padding: theme.spacing(1.2),
    border: '3px solid rgba(0, 0, 0, 0.54)',

    '&.Mui-disabled': {
      border: '3px solid rgba(0, 0, 0, 0.26)',
    },
  },
  title: {
    textAlign: 'center',
    textTransform: 'uppercase',

    '& .MuiTypography-root': {
      fontSize: 26,
      fontWeight: 'bold',
      marginTop: -15,
    },

    '& svg': {
      transform: 'scale(0.6)',
    },
  },
  avatar: {
    display: 'inline-block',
    border: `3px solid ${theme.palette.primary.main}`,
    width: 120,
    height: 120,
  },
  address: {
    fontSize: 20,
  },
  info: {
    fontSize: 17,
    fontWeight: 'bold',

    '& span': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
  more: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 17,
    fontWeight: 'bold',
    marginTop: 10,
    cursor: 'pointer',
  },
  infoUnderline: {
    fontSize: 17,
    fontWeight: 'bold',
    textDecoration: 'underline',

    '& span': {
      color: 'rgba(0, 0, 0, 0.54)',
      textDecoration: 'underline',
    },
  },
  times: {
    fontSize: 17,

    '& span': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
  moment: {
    maxWidth: 400,
    display: 'inline-block',
  },
  infoVacancy: {
    textAlign: 'center',
    fontSize: 17,

    '& span': {
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: 16,
    },
  },
  infoInterview: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
  paper: {
    position: 'absolute',
    minHeight: 50,
    maxHeight: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  sheet: {
    minHeight: 50,
    maxHeight: 550,
    overflowY: 'auto',
    width: 400,
    paddingRight: '30px',
  },
  ghostButton: {
    color: '#35BFEE',
    width: '40%',
    border: '1px solid #D9D9D9',
    cursor: 'pointer',
    outline: '0 none',
    padding: '16px',
    background: 'transparent',
    boxSizing: 'border-box',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    margin: '0 auto',
    fontWeight: 'bold',
    justifyContent: 'space-between',
    '&:hover': {
      background: '#EBF9FD',
    },
  },
  ghostHeader: {
    marginBottom: '20px',
    fontSize: '18px',
    paddingTop: '20px',
  },
  ghostClose: {
    position: 'absolute',
    right: '-12px',
    top: '-12px',
    background: '#41C0F0',
    padding: '5px',
    borderRadius: '12px',
    color: '#FFF',
    cursor: 'pointer',
  },
  right: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

export default function ModalNextInterviews({
  nextInterviews = [], setInterview, shouldUnavailabilityReason, openModalInterview,
}) {
  const theme = useTheme()
  const classes = useStyles(theme)
  const [interviews, setInterviews] = useState([])
  const [index, setIndex] = useState(0)
  const [momentId, setMomentId] = useState('')
  const [momentIds, setMomentIds] = useState([])
  const [open, setOpen] = useState(false)
  const [selectedTime, setSelectedTime] = useState()
  const [modalStyle] = useState(getModalStyle)
  const interview = interviews[index] || {}
  const workTime = interview.period?.find((p) => p.time_type === 'work') || {}
  const restTime = interview.period?.find((p) => p.time_type === 'rest') || {}
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [details, setDetails] = useState(false)

  const filterValidTimes = (item) => {
    if (!item || !item?.start_at || !item?.start_at?.datetime) return false
    const diffNow = DateTime.fromISO(item.start_at?.datetime).diffNow()
    return item.start_at?.datetime && diffNow.isValid && diffNow.values.milliseconds > 0
  }

  function removeInterview(interview) {
    const payload = interviews.filter((i) => i.id !== interview.id)
    setInterviews(payload)
    setIndex(0)
    setSelectedTime('selecione')
  }

  function handleSubmit(interview, status) {
    if (interview.freelancer) {
      if (status === 'refused') {
        setInterview(interview.id, null, status)
      }

      momentIds.map((moment) => setInterview(interview.id, moment.moment_id, status))
      return removeInterview(interview)
    }

    setInterview(interview.id, momentId || null, status)
    return removeInterview(interview)
  }

  useEffect(() => {
    if (nextInterviews && nextInterviews?.length) {
      const payload = nextInterviews.filter(
        (interview) => interview.moments_interview && interview.moments_interview?.find(filterValidTimes),
      )?.map((p) => ({
        ...p,
        moments_interview: p.moments_interview?.filter(filterValidTimes) || [],
        freelancer: p.type_opportunity === 'freelancer',
      })) || []
      setInterviews(payload)
    }
  }, [nextInterviews])

  useEffect(() => {
    setMomentId('')
    setMomentIds([])
  }, [index])

  function controlFlowModal(contentTime, id) {
    setMomentId(id)
    setSelectedTime(contentTime)
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  function rand() {
    return Math.round(Math.random() * 20) - 10
  }

  function getModalStyle() {
    const top = 50 + rand()
    const left = 50 + rand()

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    }
  }

  return (
    <Dialog
      open={!!interviews?.length && openModalInterview}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      maxWidth="md"
    >
      <Box className={classes.root}>
        <Grid container alignItems="center">
          <Grid item xs={2} md={1}>
            <IconButton
              size="medium"
              disabled={index <= 0}
              onClick={() => setIndex(index - 1)}
              className={classes.control}
            >
              <KeyboardArrowLeftIcon fontSize="medium" />
            </IconButton>
          </Grid>
          <Grid item xs={8} md={10}>
            <Box className={classes.title}>
              <InterviewsIcon />
              <Typography display="block" color="secondary" gutterBottom>
                Convite para {interview.freelancer ? 'freelancer' : 'entrevista'}
              </Typography>
            </Box>
            <Box>

              <Box textAlign="center">
                <Avatar className={classes.avatar} src={interview.logo_company} />
                <Box mt={2}>
                  <Typography variant="h5" gutterBottom>{interview.name_company}</Typography>
                </Box>
                <Box mt={2} textAlign="center" className={classes.infoInterview}>
                  <Typography variant="h6" color="primary" className={classes.info}>
                    Posição: <Typography variant="body">{interview.position}</Typography>
                  </Typography>
                  <Typography variant="h6" color="primary" className={classes.info}>
                    Salário: <Typography variant="body">{currencyFormat(Number(interview.salary))}</Typography>
                  </Typography>
                  {!!interview?.tip && (
                  <Typography variant="h6" color="primary" className={classes.info}>
                    Gorjeta: <Typography variant="body">{currencyFormat(Number(interview.tip))}</Typography>
                  </Typography>
                  )}
                  {!!interview?.tip && (
                  <Typography variant="h6" color="primary" className={classes.infoUnderline}>
                    Pagamento total: <Typography variant="body"> {currencyFormat(Number(interview.tip) + Number(interview.salary))} </Typography>
                  </Typography>
                  )}
                  <Typography onClick={() => setDetails(!details)} variant="h6" color="primary" className={classes.more}>
                    Ver detalhes {details ? <KeyboardArrowUp fontSize="medium" /> : <KeyboardArrowDown fontSize="medium" />}
                  </Typography>
                  {
                    details ? (
                      <>
                        <Typography variant="h6" color="primary" className={classes.infoVacancy}>
                          Descrição: <Typography variant="body"> {interview.description} </Typography>
                        </Typography>
                        <Typography variant="h6" color="primary" className={classes.times}>
                          Horário de trabalho: <Typography variant="body"> {workTime.start_time}h às {workTime.end_time}h </Typography>
                        </Typography>
                        {!interview.freelancer && (
                          <Typography variant="h6" color="primary" className={classes.times}>
                            Horário de descanso: <Typography variant="body"> {restTime.start_time}h às {restTime.end_time}h </Typography>
                          </Typography>
                        )}
                      </>
                    ) : null
                  }
                </Box>

                <Box mt={2} textAlign="center">
                  {!isMobile && !interview.freelancer
                    ? (
                      <div>
                        <button type="button" onClick={handleOpen} className={classes.ghostButton}>
                          <span> {momentId === '' ? 'Selecione' : selectedTime} </span>  <span style={{ font: '25px bold' }}>&#8250;</span>
                        </button>
                        <Modal
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="simple-modal-title"
                          aria-describedby="simple-modal-description"
                        >
                          <div style={modalStyle} className={classes.paper}>
                            <div
                              className={classes.ghostClose}
                              onClick={() => setOpen(false)}
                            >
                              <CloseIcon />
                            </div>
                            <div className={classes.sheet}>
                              <p className={classes.ghostHeader}>
                                Escolha a data e horário da entrevista
                              </p>
                              {interview.moments_interview?.map((moment) => (
                                <button
                                  type="button"
                                  onClick={
                                      () => controlFlowModal(`${moment.end_at.date} das ${moment.start_at.time}h até ${moment.end_at.time}h`, moment.moment_id)
                                    }
                                  className={classes.ghostButton}
                                  key={moment.moment_id}
                                  style={{
                                    marginBottom: '10px',
                                    width: '100%',
                                  }}
                                >
                                  <span>
                                    {moment.end_at.date} das {moment.start_at.time}h até {moment.end_at.time}h
                                  </span>
                                  <span style={{ font: '25px bold' }}>&#8250;</span>
                                </button>
                              ))}
                            </div>
                          </div>
                        </Modal>
                      </div>
                    )
                    : (
                      <>
                        {!interview.freelancer && (
                          <TextField
                            value={momentId}
                            className={classes.moment}
                            fullWidth
                            select
                            SelectProps={{
                              native: true,
                            }}
                            label="Horário da Entrevista"
                            onChange={({ target: { value } }) => setMomentId(value)}
                          >
                            <option value="" />
                            {interview.moments_interview?.map((moment) => (
                              <option value={moment.moment_id} key={moment.moment_id}>
                                {moment.end_at.date} das {moment.start_at.time}h até {moment.end_at.time}h
                              </option>
                            ))}
                          </TextField>
                        )}
                      </>
                    )}

                  {interview.freelancer && (
                  <Typography>
                    Selecione os dias que você tem disponível para trabalhar como freelancer
                  </Typography>
                  )}
                  {interview.freelancer && (
                  <Autocomplete
                    multiple
                    options={interview.moments_interview || []}
                    getOptionLabel={(option) => `${option.start_at.date} às ${option.start_at.time} até ${option.end_at.time}`}
                    onChange={(e, value) => setMomentIds(value)}
                    value={momentIds}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Selecione os dias de trabalho"
                      />
                    )}
                    renderTags={(value, getTagProps) => value.map((option, index) => (
                      <Chip
                        color="primary"
                        variant="outlined"
                        label={`${option.start_at.date} - ${option.start_at.time} até ${option.end_at.time}`}
                        {...getTagProps({ index })}
                      />
                    ))}
                  />
                  )}
                </Box>

              </Box>
              <Box mt={4} mb={2} display="flex" justifyContent="space-evenly">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <RedButton
                      size="large"
                      variant="contained"
                      color="error"
                      fullWidth
                      onClick={() => handleSubmit(interview, 'refused')}
                    >
                      Recusar
                    </RedButton>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <GreenButton
                      size="large"
                      variant="contained"
                      color="error"
                      fullWidth
                      disabled={!momentId && !momentIds?.length}
                      onClick={() => handleSubmit(interview, 'accepted')}
                    >
                      Aceitar
                    </GreenButton>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid className={classes.right} item xs={2} md={1}>
            <IconButton
              size="medium"
              disabled={index >= interviews.length - 1}
              onClick={() => setIndex(index + 1)}
              className={classes.control}
            >
              <KeyboardArrowRightIcon fontSize="medium" />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  )
}
