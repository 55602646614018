import React from 'react';

export default function InterviewsIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="112.924" height="98.001" viewBox="0 0 112.924 98.001" {...props}>
      <g id="meeting_2_" data-name="meeting (2)" transform="translate(0.5 -6.169)">
        <g id="Group_1026" data-name="Group 1026" transform="translate(0 6.669)">
          <g id="Group_1025" data-name="Group 1025" transform="translate(0)">
            <path id="Path_461" data-name="Path 461" d="M108.092,94.536A13.053,13.053,0,1,0,92.034,96a9.324,9.324,0,0,0-4.091,7.712V116.77H80.482v-9.327a13.07,13.07,0,0,0-11.51-12.957,13.058,13.058,0,1,0-18.02,0,13.07,13.07,0,0,0-11.51,12.957v9.327H31.981V103.712A9.324,9.324,0,0,0,27.89,96a13.064,13.064,0,1,0-16.057-1.464A9.318,9.318,0,0,0,4,103.712v27.981a9.345,9.345,0,0,0,7.462,9.139V169h3.731V141.02H31.981v26.116A1.866,1.866,0,0,0,33.847,169H45.039a1.866,1.866,0,0,0,1.865-1.865V137.289H58.1V165.27H50.635V169H69.289V165.27H61.828V137.289H73.02v29.847A1.866,1.866,0,0,0,74.885,169H86.078a1.866,1.866,0,0,0,1.865-1.865V141.02h16.789V169h3.731V140.832a9.343,9.343,0,0,0,7.462-9.139V103.712A9.318,9.318,0,0,0,108.092,94.536ZM89.809,85.058a9.327,9.327,0,1,1,9.327,9.327A9.338,9.338,0,0,1,89.809,85.058Zm-39.174,0a9.327,9.327,0,1,1,9.327,9.327A9.338,9.338,0,0,1,50.635,85.058ZM62.867,98.116l-2.9,7.745-2.9-7.745Zm-19.693,9.327A9.338,9.338,0,0,1,52.5,98.116h.573l5.143,13.713a1.866,1.866,0,0,0,3.494,0l5.141-13.713h.573a9.338,9.338,0,0,1,9.327,9.327v9.327H69.289a1.866,1.866,0,0,0-1.865,1.865V126.1H52.5v-7.462a1.866,1.866,0,0,0-1.865-1.865H43.174ZM11.462,85.058a9.327,9.327,0,1,1,9.327,9.327A9.338,9.338,0,0,1,11.462,85.058ZM43.174,165.27H35.712V139.155a1.866,1.866,0,0,0-1.865-1.865H13.327a5.6,5.6,0,0,1-5.6-5.6V103.712a5.6,5.6,0,0,1,5.6-5.6h9.327a5.6,5.6,0,0,1,5.6,5.6v14.923a1.866,1.866,0,0,0,1.865,1.865H48.77v5.6H22.654V109.308H18.923v18.654a1.866,1.866,0,0,0,1.865,1.865H37.577a5.6,5.6,0,0,1,5.6,5.6Zm30.055-31.712H46.7a9.315,9.315,0,0,0-1.66-3.731H74.889A9.315,9.315,0,0,0,73.229,133.558Zm38.965-1.865a5.6,5.6,0,0,1-5.6,5.6H86.078a1.866,1.866,0,0,0-1.865,1.865V165.27H76.751V135.424a5.6,5.6,0,0,1,5.6-5.6H99.136A1.866,1.866,0,0,0,101,127.962V109.308H97.27V126.1H71.155v-5.6H89.809a1.866,1.866,0,0,0,1.865-1.865V103.712a5.6,5.6,0,0,1,5.6-5.6H106.6a5.6,5.6,0,0,1,5.6,5.6Z" transform="translate(-4 -72)" fill="#41c0f0" stroke="#41c0f0" stroke-width="1"/>
          </g>
        </g>
      </g>
    </svg>
  );
}
