import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import DisabledProfileIcon from 'Components/icons/DisabledProfile'
import ExhaustedIcon from 'Components/icons/Exhausted'
import { Typography, Grid } from '@material-ui/core'
import ActionButtonModal from 'Components/ActionButtonModal'
import { useRouter } from 'next/router'
import useStyles from './style'

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
))

export default function ModalDisabledProfile({ open, onClose }) {
  const classes = useStyles()
  const router = useRouter()
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="confirm-dialog-description"
      className={classes.dialog}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.container}
      >
        <Grid item>
          <ExhaustedIcon />
        </Grid>
        <Grid item xs={10} md={10} sm={10}>
          <Typography variant="h5" className={classes.title}>
            Perfil desativado
          </Typography>
          <Typography variant="h5" className={classes.subTitle}>
            Empresas não te encontrarão nas buscas
          </Typography>
        </Grid>
        <Grid item xs={10} md={10} sm={10}>
          <Typography className={classes.bodyTitle}>
            Se mudar de ideia é só ativar a opção no seu perfil novamente.
            Esperamos te ver em breve.
          </Typography>
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.content}
        >
          <Grid className={classes.item} item xs={10} md={10} sm={10}>
            <ActionButtonModal title="Visualizar meu perfil" onClick={() => router.push('/')} />
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  )
}
