import { Switch, withStyles } from '@material-ui/core'

const Switcher = withStyles((theme) => ({
  root: {
    width: 80,
    height: 40,
    padding: 0,
    display: "flex"
  },
  switchBase: {
    padding: 4,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(40px)",
      color: theme.palette.success.main,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.common.white,
        borderColor: theme.palette.success.main
      }
    }
  },
  thumb: {
    width: 32,
    height: 32,
    boxShadow: "none"
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 40 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white
  },
  checked: {}
}))(Switch);

export default Switcher
