import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CreateIcon from '@material-ui/icons/Create'
import Link from 'next/link'

import {
  Avatar, Box, Grid, Paper, Typography,
} from '@material-ui/core'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import ModalInterviewResult from 'components/ModalInterviewResult'
import { service } from 'services'
import Cookies from 'js-cookie'
import Success from 'Components/FormComponents/Success'
import Carousel from '../Carousel/index'
import InfoButton from '../Button/Info'
import SuccessButton from '../Button/Success'
import useAuth from '../../context/useAuth'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  avatar: {
    marginRight: theme.spacing(2),
    border: `2px solid ${theme.palette.primary.light}`,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(12),
      height: theme.spacing(12),
    },
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
  },
  avatarPositionAvailable: {
    marginRight: theme.spacing(1),
    width: theme.spacing(6),
    height: theme.spacing(6),
    background: theme.palette.primary.light,
    fontWeight: 900,
  },
  linkEditProfile: {
    color: theme.palette.text.secondary,
    fontSize: '14px',
    display: 'flex',
    textDecoration: 'none',
  },
  containerPostition: {
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  buttonFindCandidates: {
    marginBottom: theme.spacing(1),
    fontWeight: 900,
  },
  buttonOpenNewOpportunity: {
    marginBottom: theme.spacing(1),
    fontWeight: 900,
  },
  containerMargin: {
    margin: '0',
  },
  boxImage: {
    textAlign: 'center',
    maxHeight: '340px',
    maxWidth: '340px',
    cursor: 'pointer',
    '& img': {
      color: 'transparent',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      textAlign: 'center',
      textIndent: '10000px',
      borderRadius: '10px',
      '-moz-transition': 'all 0.2s',
      '-webkit-transition': 'all 0.2s',
      transition: 'all 0.2s',
    },
    '& img:hover': {
      '-moz-transform': 'scale(1.1)',
      '-webkit-transform': 'scale(1.1)',
      transform: 'scale(1.1)',
    },
    '& span': {
      marginTop: '-50px',
    },
  },
  tittleHabitat: {
    color: '#fff',
    fontSize: '20px',
    marginBottom: '20px',
  },
}))

function HomeCompany() {
  const { session } = useAuth()
  const classes = useStyles()
  const [companyInterviews, setCompanyInterviews] = useState([])
  const [openModal, setOpenModal] = useState()
  const [image, setImage] = useState()
  const [index, setIndex] = useState()
  const images = useState(session.company.images)
  const [successCompany, _] = useState(Cookies.get('form-company-save-success'))

  useEffect(() => {
    service.calendar.getPastInterviews(session.company.id)
      .then(setCompanyInterviews)
      .catch(console.error)
  }, [])

  function handleOpenModal() {
    setOpenModal(true)
  }

  function handleInsertImage(img, index) {
    setImage(img)
    setIndex(index)
  }

  function handleCloseModal() {
    setOpenModal(false)
  }

  return (
    <>
      {!!successCompany && <Success page="company" />}
      <Paper elevation={3} className={classes.containerMargin}>
        <Box p={3}>
          <Grid container>
            <Grid item xs={12} md={4}>
              <Grid container wrap="nowrap">
                <Grid>
                  <Avatar src={session.avatar} className={classes.avatar} />
                </Grid>
                <Grid>
                  <Typography variant="h5">{session.name}</Typography>
                  <Typography variant="caption" component="span">
                    <Link href="/editar/cadastro-da-empresa">
                      <a className={classes.linkEditProfile}>
                        <CreateIcon fontSize="small" /> Editar perfil
                      </a>
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container xs={12} md={5} alignItems="center">
              <Grid
                container
                alignItems="center"
                className={classes.containerPostition}
              >
                <Grid m={2}>
                  <Avatar className={classes.avatarPositionAvailable}>
                    {session.company.available_opportunities}
                  </Avatar>
                </Grid>
                <Grid>
                  <Typography variant="body1">
                    {session.company.available_opportunities === 1
                      ? 'Vaga disponível'
                      : 'Vagas disponíveis'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                className={classes.containerPostition}
              >
                <Typography variant="caption" component="span">
                  <Link href={`/${session.company.slug}`}>
                    <a className={classes.linkEditProfile}>
                      <TrendingUpIcon fontSize="medium" /> Minha página slug
                    </a>
                  </Link>
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Grid item xs={12}>
                <Link href="/busca">
                  <SuccessButton
                    className={classes.buttonOpenNewOpportunity}
                    fullWidth
                    size="large"
                  >
                    ENCONTRAR CANDIDATOS
                  </SuccessButton>
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Link href="/vagas/novo">
                  <InfoButton
                    className={classes.buttonOpenNewOpportunity}
                    fullWidth
                    size="large"
                  >
                    ABRIR NOVA VAGA
                  </InfoButton>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      {session.company.images.length !== 0 && (
        <Box mt={3}>
          <Typography variant="body1" className={classes.tittleHabitat}>
            Fotos do ambiente
          </Typography>
          <Grid container spacing={3}>
            {session.company.images.map((img, index) => (
              <Grid
                item
                xs={3}
                md={3}
                className={classes.boxImage}
                key={index}
                onClick={handleOpenModal}
              >
                <img src={img.image} key={index} onClick={() => handleInsertImage(img, index)} />
              </Grid>
            ))}
          </Grid>
          {openModal && (
            <Carousel
              open={handleOpenModal}
              handleClose={handleCloseModal}
              images={session.company.images}
              index={index}
            />
          )}
        </Box>
      )}
      {!!companyInterviews.length && (
      <ModalInterviewResult
        open
        interviews={companyInterviews}
        setCompanyInterviews={setCompanyInterviews}
      />
      )}
    </>
  )
}

export default HomeCompany
