import React, { useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {
  Typography,
  Grid,
  TextField,
  Button,
  withStyles,
} from '@material-ui/core'
import ActionButtonModal from 'Components/ActionButtonModal'
import ModalDisabledProfile from 'Components/ModalDisabledProfile'
import { service } from 'services'
import useSnackbar from 'context/useSnackbar'
import useStyles from './style'

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
))

const BlueButton = withStyles((theme) => ({
  root: {
    backgroundColor: '#41C0F0',
    color: theme.palette.success.contrastText,
    fontSize: 19,
    fontWeight: 'bold',
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    width: '100%',
    '&:hover': {
      backgroundColor: '#0B2C46',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      '& span': {
        fontSize: '12px',
      },
    },
  },
}))(Button)

export default function ModalDisableFeedback({
  open, onClose, reasons,
}) {
  const classes = useStyles()

  const [openModal, setOpenModal] = useState(open)

  useEffect(() => setOpenModal(open), [reasons])

  const [openFeedback, setOpenFeedback] = useState(false)
  const [messageReason, setMessageReason] = useState('')
  const [emptyMessage, setEmptyMessage] = useState('')

  const [openModalDisabledProfile, setOpenModalDisabledProfile] = useState(false)
  const { updateSnackbar } = useSnackbar()
  const handleBack = () => {
    onClose()
  }
  const handleChange = (e) => {
    setMessageReason(e.target.value)
  }

  const handleSubmit = (message) => {
    if (message.length) {
      const body = {
        reason_id: 4,
        other_description: message,
      }
      service.people
        .updateProfileReason(body)
        .then(() => {
          setOpenModalDisabledProfile(true)
          setOpenModal(false)
        })
        .catch(() => {
          updateSnackbar({
            open: true,
            message: 'Algo deu errado, tente novamente mais tarde.',
            anchorOrigin: { vertical: 'bottom' },
            severity: 'error',
          })
        })
    } else {
      setEmptyMessage('Mensagem obrigatória')
    }
  }

  const handleDisableProfile = (reason) => {
    if (reason.id === 4) {
      setOpenFeedback(true)
    } else {
      const body = {
        reason_id: reason.id,
      }
      service.people
        .updateProfileReason(body)
        .then(() => {
          setOpenModal(false)
          setOpenModalDisabledProfile(true)
        })
        .catch(() => {
          updateSnackbar({
            open: true,
            message: 'Algo deu errado, tente novamente mais tarde.',
            anchorOrigin: { vertical: 'bottom' },
            severity: 'error',
          })
        })
    }
  }

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={openModal}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="confirm-dialog-description"
      className={classes.dialog}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.container}
      >
        <Grid
          container
          direction="row"
          justifyContent="start"
          alignItems="center"
          className={classes.container}
        >
          <Grid item>
            <ArrowBackIcon
              onClick={() => {
                handleBack()
                setOpenModal(false)
              }}
              className={classes.icon}
            />
          </Grid>
        </Grid>
        {openFeedback ? (
          <>
            <Grid item>
              <Typography variant="h5" className={classes.title}>
                Desativar Perfil
              </Typography>
              <Typography variant="h5" className={classes.subTitle}>
                Nos ajude entender melhor seu motivo?
              </Typography>
            </Grid>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              className={classes.content}
            >
              <Grid className={classes.item} item xs={10} md={10} sm={10}>
                <TextField
                  id="outlined-multiline-static"
                  label="Descreva aqui seu motivo"
                  error={messageReason.length === 0}
                  helperText={emptyMessage}
                  multiline
                  style={{ width: '100%' }}
                  rows={5}
                  variant="outlined"
                  onChange={handleChange}
                  value={messageReason}
                />
              </Grid>
              <Grid className={classes.item} item xs={10} md={10} sm={10}>
                <BlueButton onClick={() => {
                  handleSubmit(messageReason)
                }}
                >
                  Enviar
                </BlueButton>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid item>
              <Typography variant="h5" className={classes.title}>
                Desativar Perfil
              </Typography>
              <Typography variant="h5" className={classes.subTitle}>
                Qual é o motivo?
              </Typography>
            </Grid>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              className={classes.content}
            >
              <Grid className={classes.item} item xs={10} md={10} sm={10}>
                {
                  reasons?.length ? (
                    reasons.map((reason) => (
                      <ActionButtonModal
                        onClick={() => handleDisableProfile(reason)}
                        title={reason.name}
                      />
                    ))
                  )
                    : (
                      <div>Carregando</div>
                    )
                }
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      <ModalDisabledProfile open={openModalDisabledProfile} />
    </Dialog>
  )
}
