import React from 'react';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Typography, Grid } from '@material-ui/core';
import useStyles from './style';

export default function ActionButtonModal({ title, onClick }) {
  const classes = useStyles();
  return (
    <div onClick={onClick} className={classes.container}>
      <Typography className={classes.title} variant="h6">
        {title}
      </Typography>
      <ArrowForwardIosIcon className={classes.icon} />
    </div>
  );
}
