import Styles from '../../styles/BoxContent/BoxContent.module.scss';

function BoxContents({ children }) {
  return (
    <div className={Styles.boxContent}>
      {children}
    </div>
  );
}

export default BoxContents;
