import DoneIcon from '@material-ui/icons/Done';
import ChatIcon from '@material-ui/icons/Chat';
import Styles from '../../styles/Register/Register.module.scss';
import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'js-cookie';
import { useEffect } from 'react';

const useStyles = makeStyles({
  container: {
    backgroundColor: 'rgba(0,0,0, .9)',
    position: 'fixed',
    width: '100%',
    height: '100%',
    left: '0',
    right: '0',
    bottom: '0',
    zIndex: '10',
  },
  content: {
    top: '50%',
    left: '50%',
    height: 'auto',
    display: 'table',
    position: 'absolute',
    transform: 'translateY(-50%) translateX(-50%)'
  },
  link: {
    textDecoration: 'none'
  }
});

function Success({page}) {
  const classes = useStyles();

  useEffect(() => {
    Cookies.remove('form-candidate-save-success')
    Cookies.remove('form-company-save-success')
  }, [])

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <section className={Styles.register__success}>
          <div className={Styles.register__successContent}>
            <div className={Styles.register__successContentIcon}>
              <i className={Styles.register__successIcon}>
                <DoneIcon/>
              </i>
            </div>
            <h3 className={Styles.register__successTitle}>Dados salvos<br/>com sucesso!</h3>
          </div>
          {page !== 'company'
            ? <div className={Styles.register__successBox}>
              <div className={Styles.register__successLamp}>
                <ChatIcon/> <span>DICA!</span>
              </div>
              <p className={Styles.register__successText}>Realize provas para aumentar sua pontuação, estude nossos
                cursos e candidate-se para sua próxima vaga de emprego!</p>
            </div>
            : null
          }
          <a href="/inicio" className={classes.link}>
            <button type="button" className={Styles.button__goHome}>Ir para o início</button>
          </a>
        </section>
      </div>
    </div>
  );
}

export default Success;
