import useAuth, { ProtectRoute } from '../../context/useAuth';
import LayoutDefault from '../../Components/Layout/LayoutDefault';

import HomeCandidate from '../../Components/Home/HomeCandidate';
import HomeCompany from '../../Components/Home/HomeCompany';

import InterviewProvider from '../../context/useInterview';

function Home() {
  const { session } = useAuth()

  return (
    <LayoutDefault>
      {session.isCompany && <HomeCompany />}
      {!session.isCompany &&
        <InterviewProvider>
          <HomeCandidate />
        </InterviewProvider>
      }
    </LayoutDefault>
  )
}

export default ProtectRoute(Home)
