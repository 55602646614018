import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { IoIosRocket } from '@react-icons/all-files/io/IoIosRocket';
import CheckIcon from '@material-ui/icons/Check';
import { Box, useTheme } from '@material-ui/core';
import createArray from 'utils/createArray'

const Connector = withStyles((theme) => ({
  root: {
    '& $line': {
      [theme.breakpoints.up('sm')]: {
        marginTop: 7,
      },
      marginTop: 0,
      backgroundColor: theme.palette.primary.main,
    },
  },
  active: {
    '& $line': {
      backgroundColor: theme.palette.success.main,
    },
  },
  completed: {
    '& $line': {
      backgroundColor: theme.palette.success.main,
    },
  },
  line: {
    height: 10,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
}))(StepConnector);

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1,
    color: '#fff',
    width: 35,
    height: 35,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.success.main,

    [theme.breakpoints.up('sm')]: {
      width: 45,
      height: 45,
    },
  },
  active: {
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  steps: {
    marginLeft: '-8%',
    marginRight: '-8%',

    [theme.breakpoints.up('md')]: {
      marginLeft: '-10%',
      marginRight: '-10%',
    },

    [theme.breakpoints.up('lg')]: {
      marginLeft: '-11%',
      marginRight: '-11%',
    },

    '& .MuiStepper-root': {
      padding: 0,
      paddingBottom: theme.spacing(2)
    },
  },
}));

function StepIcon(props) {
  const theme = useTheme()
  const classes = useStyles(theme);
  const { active, completed } = props;

  const icons = {
    1: completed || active ? <CheckIcon fontSize="large"/> : <IoIosRocket fontSize="34px"/>,
    2: completed || active ? <CheckIcon fontSize="large" /> : <IoIosRocket fontSize="34px"/>,
    3: completed || active ? <CheckIcon fontSize="large"/> : <IoIosRocket fontSize="34px"/>,
    4: completed || active ? <CheckIcon fontSize="large"/> : <IoIosRocket fontSize="34px"/>,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

StepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

export default function ScoreStepper({activeStep}) {
  const theme = useTheme()
  const classes = useStyles(theme)
  return (
      <Box overflow="hidden">
        <Box className={classes.steps}>
          <Stepper alternativeLabel activeStep={activeStep} connector={<Connector />}>
            {createArray(4).map(() => (
              <Step>
                <StepLabel StepIconComponent={StepIcon} />
              </Step>
            ))}
          </Stepper>
        </Box>
      </Box>
  );
}
