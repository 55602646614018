import { makeStyles } from '@material-ui/core/styles';

const style = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #D9D9D9',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '70px',
    padding: '10px',
    marginBottom: '16px',
    marginTop: '16px',
    borderRadius: '5px',
    '&:hover': {
      cursor: 'pointer',
      border: '2px solid #41C0F0',
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: '14px',
  },
  icon: {
    fontSize: '18px',
  },
}));

export default style;
