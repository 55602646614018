import React from 'react';

export default function CongratulationIcon(props) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" id="congratulation" width="80.479" height="80.472" viewBox="0 0 118.479 118.472" {...props}>
        <path id="Path_478" data-name="Path 478" d="M108.557,218.331l-4.027,4.026-2.808.961L82.772,229.8l-19.048,6.515-18.852,6.45L28.026,248.53H24V244.5l3.445-8.269,4.9-11.755,4.9-11.752L42.5,200.111l4.537-10.889,4.9-11.752,2.267-5.443L58.225,168,77.15,186.925,98.89,208.665Z" transform="translate(-24 -130.058)" fill="#41c0f0"/>
        <g id="Group_1050" data-name="Group 1050" transform="translate(3.445 47.412)">
          <path id="Path_479" data-name="Path 479" d="M73.969,404.268l-18.852,6.45L37.69,404.185l4.9-11.755Z" transform="translate(-37.69 -345.421)" fill="#37a2cb"/>
          <path id="Path_480" data-name="Path 480" d="M141.092,318.808l-18.95,6.483L76.61,308.216,81.867,295.6Z" transform="translate(-66.816 -272.959)" fill="#37a2cb"/>
          <path id="Path_481" data-name="Path 481" d="M167.386,236.825,115.53,217.382l4.9-11.752,25.218,9.455Z" transform="translate(-95.941 -205.63)" fill="#37a2cb"/>
        </g>
        <path id="Path_482" data-name="Path 482" d="M170.066,58.225l8.419-8.419a5.626,5.626,0,0,0-1.762-9.149l-5.242-2.247a22.146,22.146,0,0,1-8.993-7.068L160,28.027,166.04,24a21.156,21.156,0,0,0,10.913,8.335l2.4.8A12.921,12.921,0,0,1,185.075,53.8l-8.969,10.464Z" transform="translate(-125.775 -22.297)" fill="#1fe390"/>
        <path id="Path_483" data-name="Path 483" d="M306.013,84.08,304,72l12.08,4.027Z" transform="translate(-233.536 -58.217)" fill="#bfbfbf"/>
        <path id="Path_484" data-name="Path 484" d="M0,0H4.027V8.3H0Z" transform="translate(59.941 5.682) rotate(-75.936)" fill="#37a2cb"/>
        <path id="Path_485" data-name="Path 485" d="M0,0H7.259V4.027H0Z" transform="matrix(0.832, -0.555, 0.555, 0.832, 79.411, 4.029)" fill="#1fe390"/>
        <path id="Path_486" data-name="Path 486" d="M0,0H4.027V8.3H0Z" transform="translate(32.277 18.276) rotate(-14.064)" fill="#41c0f0"/>
        <path id="Path_487" data-name="Path 487" d="M334.04,258.119l8.419-8.419a5.626,5.626,0,0,1,9.149,1.762l2.247,5.242a22.146,22.146,0,0,0,7.068,8.993l3.317,2.488,4.027-6.04a21.155,21.155,0,0,1-8.335-10.913l-.8-2.4a12.921,12.921,0,0,0-20.667-5.724L328,252.08Z" transform="translate(-251.496 -183.939)" fill="#1fe390"/>
        <path id="Path_488" data-name="Path 488" d="M416,178.066l12.08,2.013L424.053,168Z" transform="translate(-317.351 -130.058)" fill="#1fe390"/>
        <path id="Path_489" data-name="Path 489" d="M0,0H4.027V8.3H0Z" transform="translate(110.8 56.486) rotate(-14.064)" fill="#37a2cb"/>
        <path id="Path_490" data-name="Path 490" d="M0,0H7.259V4.027H0Z" transform="matrix(0.555, -0.832, 0.832, 0.555, 111.1, 36.842)" fill="#bfbfbf"/>
        <path id="Path_491" data-name="Path 491" d="M0,0H4.027V8.3H0Z" transform="translate(92.156 84.24) rotate(-75.936)" fill="#41c0f0"/>
        <path id="Path_492" data-name="Path 492" d="M256,104.265l8.018-9.361a15.78,15.78,0,0,1,10.243-5.419l.229-.025a16.442,16.442,0,0,0,14.258-12.77h0a11.29,11.29,0,0,1,8.05-8.443L312.371,64l2.013,8.053L303.5,74.472a11.332,11.332,0,0,0-8.661,8.877l-.057.291a17.253,17.253,0,0,1-15.212,13.839l-.238.024a17.662,17.662,0,0,0-11.856,6.275c-2.639,3.173-5.434,6.527-5.434,6.527Z" transform="translate(-197.616 -52.23)" fill="#37a2cb"/>
      </svg>
    );
}
