import { makeStyles } from '@material-ui/core/styles';

const style = makeStyles((theme) => ({
  container: {
    padding: '20px',
  },
  content: {
    marginTop: '32px',
  },
  title: {
    fontWeight: 'bold',
    marginTop: '16px',
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  subTitle: {
    fontWeight: 'bold',
    marginTop: '16px',
    textAlign: 'center',
    color: '#000',
  },
  bodyTitle: {
    marginTop: '12px',
    textAlign: 'center',
    color: '#000',
  },
  item: {
    width: '100%',
  },
}));

export default style;
