import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const SuccessButton = withStyles((theme) => ({
  root: {
    color: '#fff',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.success.dark,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
      boxShadow: '0px 10px 15px 0px rgba(0,0,0,0.35)',
    },
  },
}))(Button)

export default SuccessButton
