import React, {
  createContext,
  useState,
  useContext,
  useEffect
 } from "react";
import { service } from '../services';

const InterviewContext = createContext({});

export default function InterviewProvider({ children }) {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [interviewData, setInterviewData] = useState([])
  const [nextInterviews, setNextInterviews] = useState([])
  const [acceptedsInterview, setAccepstedInterview] = useState(false)
  const [makedInterviews, setMakedInterviews] = useState([])
  const [acceptedInterview, setAcceptedInterview] = useState(false)

  function resetInterview(){
    setAcceptedInterview(false)
  }

  const getInterviews = (peopleId) => {
    setLoading(true)

    service.interview
      .getInterview(peopleId)
      .then(setInterviewData)
      .catch(setError)
      .finally(() => setLoading(false))
  }

  const getNextInterviews = (peopleId) => {
    setLoading(true)

    service.interview
      .getNextInterview(peopleId)
      .then(setNextInterviews)
      .catch(setError)
      .finally(() => setLoading(false))
  }

  const getAcceptedInterviews = (peopleId) => {
    setLoading(true)

    service.interview
      .getAcceptedInterview(peopleId)
      .then(setAccepstedInterview)
      .catch(setError)
      .finally(() => setLoading(false))
  }

  const getMakedInterviews = (peopleId) => {
    setLoading(true)

    service.interview
      .getMakedInterview(peopleId)
      .then(setMakedInterviews)
      .catch(setError)
      .finally(() => setLoading(false))
  }

  function setInterview(candidateId, datetime, status) {
    setLoading(true)

    service.interview
      .acceptInterview(candidateId, datetime, status)
      .then(setAcceptedInterview)
      .catch(setError)
      .finally(() => setLoading(false))
  }

  return (
    <InterviewContext.Provider
      value={{
        getInterviews,
        interviewData,
        getNextInterviews,
        nextInterviews,
        makedInterviews,
        getMakedInterviews,
        acceptedInterview,
        setInterview,
        loading,
        acceptedsInterview,
        getAcceptedInterviews,
        setAcceptedInterview,
        resetInterview
      }}>
      {children}
    </InterviewContext.Provider>
  )
}

export function useInterview() {
  const context = useContext(InterviewContext);
  const {
    getInterviews,
    interviewData,
    getNextInterviews,
    nextInterviews,
    makedInterviews,
    getMakedInterviews,
    acceptedInterview,
    setInterview,
    loading,
    acceptedsInterview,
    getAcceptedInterviews,
    setAcceptedInterview,
    resetInterview
  } = context;

  return {
    getInterviews,
    interviewData,
    getNextInterviews,
    nextInterviews,
    makedInterviews,
    acceptedInterview,
    getMakedInterviews,
    setInterview,
    loading,
    acceptedsInterview,
    getAcceptedInterviews,
    setAcceptedInterview,
    resetInterview
  };
}
