import { makeStyles } from '@material-ui/core/styles'

const style = makeStyles((theme) => ({
  container: {
    padding: '20px',
  },
  content: {
    marginTop: '32px',
  },
  title: {
    fontWeight: 'bold',
    marginTop: '16px',
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  subTitle: {
    // fontWeight: 'bold',
    textAlign: 'center',
    color: '#000',
  },
  bodyTitle: {
    marginTop: '12px',
    textAlign: 'center',
    color: '#000',
  },
  item: {
    width: '100%',
  },
  link: {
    color: theme.palette.primary.main,
    marginLeft: '5px',
    fontSize: '14px',
    textDecorationLine: 'none',
  },
  containerLink: {
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export default style
