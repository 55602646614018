import React, { useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import UnresolvedProfileIcon from 'Components/icons/UnresolvedProfile'
import { Typography, Grid } from '@material-ui/core'
import ActionButtonModal from 'Components/ActionButtonModal'
import ModalActivatedprofile from 'Components/ModalActivatedProfile'
import ModalDisableFeedback from 'Components/ModalDisableFeedback'
import { service } from 'services'
import { useRouter } from 'next/router'
import useSnackbar from 'context/useSnackbar'
import ExhaustedIcon from 'Components/icons/Exhausted'

import useStyles from './style'

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
))

export default function ModalWarningDisabled({
  open, onClose, name,
}) {
  const classes = useStyles()
  const router = useRouter()
  const [openModalActivatedProfile, setOpenModalActivatedProfile] = useState(false)
  const [openModalDisableFeedback, setOpenModalDisableFeedback] = useState(false)
  const [openUnresolved, setOpenUnresolved] = useState(open)
  const { updateSnackbar } = useSnackbar()

  const [reasons, setReasons] = useState([])
  const firstName = name.split(' ')[0]

  const handleClose = () => {
    setOpenUnresolved(false)
  }

  const handleBack = () => {
    setOpenUnresolved(true)
    setOpenModalDisableFeedback(false)
  }

  const handleAvailableToWorkProfile = () => {
    const body = {
      available_to_work: true,
      type: 'modal_activate',
    }
    service.people
      .availableToWorkProfile(body)
      .then(() => {
        setOpenModalActivatedProfile(true)
        handleClose()
      })
      .catch(() => {
        updateSnackbar({
          open: true,
          message: 'Algo deu errado, tente novamente mais tarde.',
          anchorOrigin: { vertical: 'bottom' },
          severity: 'error',
        })
      })
  }

  const handleUpdateProfile = () => {
    const body = {
      available_to_work: true,
      type: 'modal_update_profile',
    }
    service.people
      .availableToWorkProfile(body)
      .then(() => {
        router.push('/editar/cadastro-do-candidato')
        handleClose()
      })
      .catch(() => {
        updateSnackbar({
          open: true,
          message: 'Algo deu errado, tente novamente mais tarde.',
          anchorOrigin: { vertical: 'bottom' },
          severity: 'error',
        })
      })
  }

  const handleDisableFeedback = () => {
    service.people
      .listUnavailabilityReasons()
      .then((data) => {
        setReasons(data)
        setOpenModalDisableFeedback(true)
        handleClose()
      })
      .catch(() => {
        updateSnackbar({
          open: true,
          message: 'Algo deu errado, tente novamente mais tarde.',
          anchorOrigin: { vertical: 'bottom' },
          severity: 'error',
        })
      })
  }

  const handleCloseDisabledFeedback = () => {
    setOpenModalDisableFeedback(false)
  }

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={openUnresolved}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="confirm-dialog-description"
      className={classes.dialog}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.container}
      >
        <Grid item>
          <ExhaustedIcon />
        </Grid>
        <Grid item>
          <Typography variant="h5" className={classes.title}>
            Atenção
          </Typography>
          <Typography variant="h5" className={classes.subTitle}>
            Seu perfil foi desativado
          </Typography>
        </Grid>
        <Grid item xs={10} md={10} sm={10}>
          <Typography className={classes.bodyTitle}>
            Atenção, {firstName}
          </Typography>
          <Typography className={classes.bodyTitle}>
            Seu perfil foi desativado porque você não está repondendo nossos
            convites de entrevista. Mas não se preocupe você ainda pode reativar
            seu perfil ou editar suas informações para receber os convites em outro contato:
          </Typography>
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.content}
        >
          <Grid className={classes.item} item xs={10} md={10} sm={10}>
            <ActionButtonModal
              onClick={() => handleAvailableToWorkProfile()}
              title="Reativar minha conta"
            />
            <ActionButtonModal
              onClick={() => handleUpdateProfile()}
              title="Editar informações do meu perfil"
            />
            <ActionButtonModal
              onClick={() => handleDisableFeedback()}
              title="Deixar meu perfil inativo por enquanto"
              setOpenUnresolved={setOpenUnresolved}
            />
          </Grid>
        </Grid>
      </Grid>
      <ModalActivatedprofile open={openModalActivatedProfile} />
      <ModalDisableFeedback
        reasons={reasons}
        open={!!reasons.length}
        onClose={() => handleBack()}
      />
    </Dialog>
  )
}
