import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CreateIcon from '@material-ui/icons/Create'
import {
  Avatar,
  Box,
  Button,
  Chip,
  FormGroup,
  Grid,
  Typography,
  useTheme,
  withStyles,
} from '@material-ui/core'
import Link from 'next/link'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import Image from 'next/image'
import Loading from 'components/Loading'
import FeedCandidate from 'components/FeedCandidate'

import useAuth from 'context/useAuth'
import { useInterview } from 'context/useInterview'
import Success from 'components/FormComponents/Success'
import Cookies from 'js-cookie'
import HealthScoreCandidate from 'components/HealthScoreCandidate'
import RocketIcon from 'components/icons/Rocket'
import { scoreLevels, scoreStepLevels } from 'utils/score'
import ModalNextInterviews from 'components/ModalNextInterviews'
import ModalUnresolvedProfile from 'Components/ModalUnresolvedProfile'
import ModalWarningInterview from 'Components/ModalWarningInterview'
import ModalWarningDisabled from 'Components/ModalWarningDisabled'
import { CurriculumDownload } from '@w550/worc-ui'
import AssignmentReturnedOutlinedIcon from '@material-ui/icons/AssignmentReturnedOutlined'
import { DateTime } from 'luxon'
import BoxContents from '../BoxContent'
import Switcher from '../Switcher'
import { service } from '../../services'
import Style from '../../styles/TechnicalTest/TechnicalTest.module.scss'

const BlueChip = withStyles((theme) => ({
  root: {
    borderColor: theme.palette.primary.light,
    color: theme.palette.primary.light,
  },
  checked: {},
}))(Chip)

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '4px',
    boxShadow: theme.shadows[5],
    outline: '0',
    textAlign: 'center',
    '& button': {
      marginRight: '15px',
    },
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  },
  theJobInfos: {
    fontSize: '14px',
    color: '#169e65',
    fontWeight: '700',
  },
  avatar: {
    marginRight: theme.spacing(2),
    border: `5px solid ${theme.palette.primary.light}`,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(20),
      height: theme.spacing(20),
    },
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(12),
      height: theme.spacing(12),
    },
  },
  name: {
    fontSize: 20,
    fontWeight: 'normal',
  },
  avatarPositionAvailable: {
    marginRight: theme.spacing(1),
    width: theme.spacing(6),
    height: theme.spacing(6),
    background: theme.palette.primary.light,
    fontWeight: 900,
  },
  linkEditProfile: {
    color: theme.palette.text.secondary,
    fontSize: '14px',
    display: 'flex',
    textDecoration: 'none',
    marginBottom: '10px',
    marginTop: '15px',

    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  labelYesSwitcher: {
    color: theme.palette.success.main,
  },
  headerTitle: {
    fontWeight: '700',
    display: 'block',
    marginBottom: '20px',
    borderBottom: '1px solid',
    paddingBottom: '20px',
  },
  titleJob: {
    marginBottom: '25px',
    fontSize: '14px',
  },
  titleShedule: {
    fontWeight: '700',
    marginBottom: '15px',
    fontSize: '14px',
    '& p': {
      margin: '10px 0',
      textAlign: 'left',
    },
    '& span': {
      marginLeft: '10px',
    },
  },
  confirm: {
    marginBottom: '15px',
  },
  left: {
    float: 'left',
    width: '40%',
    backgroundColor: 'rgb(112, 128, 144)',
    minHeight: '409px',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    padding: '10px',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  right: {
    float: 'left',
    width: '60%',
    padding: '16px',
    [theme.breakpoints.down('md')]: {
      float: 'none',
      margin: '0 auto',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '10px',
      width: '100%',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
  chooseYourDestiny: {
    margin: '20px',
  },
  button: {
    backgroundColor: theme.palette.primary.light,
    color: '#ffffff',
    borderRadius: '6px',
    padding: '4px',
    lineHeight: 1,
    width: '100%',
    height: '6vh',
  },
  profileBox: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  profile: {
    [theme.breakpoints.down('md')]: {
      display: 'inline-flex',
      width: 'auto',
      textAlign: 'left',
    },
  },
  match: {
    [theme.breakpoints.down('md')]: {
      margin: 'auto',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
      display: 'inline-block',
    },
  },
  matchTitle: {
    display: 'block',
    width: '100%',
    marginBottom: theme.spacing(1),

    [theme.breakpoints.down('md')]: {
      display: 'inline-block',
    },
  },
  matchBody: {
    display: 'flex',
    fontWeight: 'bold !important',
    alignItems: 'center',

    '& svg': {
      marginRight: theme.spacing(2),
    },

    '& h4': {
      marginBottom: theme.spacing(2),
      lineHeight: '100%',
    },

    '& h6': {
      lineHeight: '100%',
      color: theme.palette.success.main,
      fontWeight: 'bold !important',
    },
  },
  curriculum: {
    '& > button': {
      background: 'transparent !important',
      padding: '0 !important',
      fontSize: '14px !important',
    },
    '& > a': {
      background: 'transparent !important',
      padding: '0 !important',
      fontSize: '14px !important',
    },
  },
}))

function HomeCandidate(module) {
  const theme = useTheme()
  const classes = useStyles(theme)
  const { session } = useAuth()

  const {
    setInterview,
    acceptedInterview,
    setAcceptedInterview,
    nextInterviews,
    getNextInterviews,
    loading,
  } = useInterview()
  const [isAvailable, setIsAvailable] = useState(
    session.candidate.available_to_work,
  )
  const [errorAvailable, setErrorAvailable] = useState()
  const [slugMessage, setSlugMessage] = useState(
    !!Cookies.get('candidate-slugged'),
  )
  const [successCandidate, _] = useState(
    Cookies.get('form-candidate-save-success'),
  )
  const [openModalInterview, setOpenModalIntervew] = useState(false)

  const level = scoreStepLevels(Number(session.candidate.healthscore))
  const levelText = scoreLevels[level]

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
  }

  function handleOpenModalInterviews() {
    return setOpenModalIntervew(true)
  }

  async function handleUpdateAvailability(e) {
    const { checked } = e.currentTarget
    await service.people
      .update(session.candidate.id, { available_to_work: checked })
      .then(() => setIsAvailable(checked))
      .catch(({ response }) => {
        const errors = response?.data?.non_field_errors || []
        const error = errors[0]
          || 'Desculpe, não foi possível alterar o status de disponível para trabalho'
        setErrorAvailable(error)
      })
  }

  const filterValidTimes = (item) => {
    if (!item || !item?.start_at || !item?.start_at?.datetime) return false
    const diffNow = DateTime.fromISO(item.start_at?.datetime).diffNow()
    return item.start_at?.datetime && diffNow.isValid && diffNow.values.milliseconds > 0
  }

  const nextInterviewsActive = nextInterviews.filter(
    (interview) => interview.moments_interview && interview.moments_interview?.find(filterValidTimes),
  )

  function Evidences() {
    return (
      <div className={Style.cardContainer}>
        <div className={Style.card}>
          <div className={Style.cardEvidencesTecnical}>
            <div className={Style.leftIcon}>
              <Image src="/cozinheiro.png" width="100%" height="100%" />
            </div>
            <div className={Style.rigthButton}>
              <Link href="/provas">
                <Button className={classes.button}>FAZER TESTE TÉCNICO</Button>
              </Link>
            </div>
          </div>
        </div>
        <div className={Style.card}>
          <div className={Style.cardEvidencesProfessional}>
            <div className={Style.leftIcon}>
              <Image src="/cozinheira.png" width="100%" height="100%" />
            </div>
            <div className={Style.rigthButton}>
              <Link href="/provas">
                <Button className={classes.button}>
                  FAZER TESTE DE PERFIL PROFISSIONAL
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }

  useEffect(() => {
    getNextInterviews(session.candidate.id)
  }, [])

  return (
    <>
      <BoxContents>
        {loading && <Loading />}
        <Snackbar
          open={acceptedInterview && !!acceptedInterview?.length}
          autoHideDuration={5000}
          onClose={() => setAcceptedInterview(false)}
        >
          <Alert onClose={() => setAcceptedInterview(false)} severity="success">
            Parabéns! Sua entrevista foi atualizada com sucesso!
          </Alert>
        </Snackbar>
        <Snackbar
          open={!!errorAvailable}
          autoHideDuration={5000}
          onClose={() => setErrorAvailable(null)}
        >
          <Alert onClose={() => setErrorAvailable(false)} severity="error">
            {errorAvailable}
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={slugMessage}
        >
          <Alert
            severity="info"
            onClose={() => {
              Cookies.remove('candidate-slugged')
              setSlugMessage(false)
            }}
          >
            Parabéns, sua inscrição foi salva com sucesso.
          </Alert>
        </Snackbar>
        {successCandidate && <Success page="candidate" />}
        {!successCandidate && (
          <ModalNextInterviews
            nextInterviews={nextInterviews}
            setInterview={setInterview}
            openModalInterview={session.candidate?.unavailability_step ? openModalInterview : true}
            shouldUnavailabilityReason={session.candidate.should_unavailability_reason}

          />
        )}
        <div className={classes.root}>
          <Grid container alignItems="center" spacing={4}>
            <Grid item xs={12} md={5} className={classes.profileBox}>
              <Grid container wrap="nowrap" className={classes.profile} alignItems="center">
                <Grid>
                  <Avatar
                    src={session.avatar}
                    className={classes.avatar}
                    alt={session.candidate.name}
                  />
                </Grid>
                <Grid>
                  <Typography variant="h5" className={classes.name}>{session.name}</Typography>
                  {!!session.candidate.years_old && (
                    <Box>
                      <Typography variant="body1">
                        {session.candidate.years_old} Anos
                      </Typography>
                    </Box>
                  )}
                  {!!(
                    session.candidate.positions_list
                    && session.candidate.positions_list?.length
                  ) && (
                    <Box mb={2}>
                      {session.candidate.positions_list[0].name}
                    </Box>
                  )}
                  <Typography vatiant="caption" component="span">
                    <Link href="/editar/cadastro-do-candidato">
                      <a className={classes.linkEditProfile}>
                        <CreateIcon fontSize="small" /> Editar perfil
                      </a>
                    </Link>
                  </Typography>
                  {!!session.candidate.curriculum_file && (
                    <Box className={classes.curriculum}>
                      <Button variant="text" color="primary" href={session.candidate.curriculum_file} target="_blank">
                        <AssignmentReturnedOutlinedIcon fontSize="small" /> Baixar meu currículo
                      </Button>
                    </Box>
                  )}
                  {!session.candidate.curriculum_file && (
                    <Box className={classes.curriculum}>
                      <Button variant="text" color="primary" href={`/curriculo/${session.candidate.id}`} target="_blank">
                        <AssignmentReturnedOutlinedIcon fontSize="small" /> Baixar meu currículo
                      </Button>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box textAlign="center">
                <Box className={classes.match} textAlign="left">
                  <Typography className={classes.matchTitle}>
                    Nível do perfil
                  </Typography>
                  <Box className={classes.matchBody}>
                    <RocketIcon />
                    <Box>
                      <Typography variant="h4">
                        {session.candidate.healthscore}%
                      </Typography>
                      <Typography variant="h6">{levelText}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormGroup>
                <Typography component="div">
                  <Grid
                    spacing={5}
                    container
                    alignItems="center"
                    justify="center"
                  >
                    <Grid item>
                      <Typography color="textPrimary">
                        Disponível para trabalho?
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    component="label"
                    container
                    alignItems="center"
                    spacing={1}
                    justify="center"
                  >
                    <Grid item>
                      <Typography color="textSecondary">Não</Typography>
                    </Grid>
                    <Grid item>
                      <Switcher
                        checked={isAvailable}
                        onChange={handleUpdateAvailability}
                        name="is_available"
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        className={isAvailable && classes.labelYesSwitcher}
                        color="textSecondary"
                      >
                        Sim
                      </Typography>
                    </Grid>
                  </Grid>
                </Typography>
              </FormGroup>
            </Grid>
          </Grid>
        </div>
      </BoxContents>
      {Number(session.candidate.healthscore) < 100 && (
        <Box mt={4}>
          <HealthScoreCandidate />
        </Box>
      )}
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Box mt={4}>
            <FeedCandidate positions={session.candidate?.positions} />
          </Box>
        </Grid>
        <Grid item xs={12} md={4} spacing={2}>
          <Evidences />
        </Grid>
      </Grid>
      <ModalUnresolvedProfile
        name={session.candidate.name}
        openInterviews={() => handleOpenModalInterviews()}
        qtdInterview={nextInterviewsActive.length}
        open={session.candidate.unavailability_step === 'availability_task_1'}
      />
      <ModalWarningDisabled
        name={session.candidate.name}
        open={session.candidate.unavailability_step === 'availability_task_3'}
      />
      <ModalWarningInterview
        name={session.candidate.name}
        qtdInterview={nextInterviewsActive.length}
        openInterviews={() => handleOpenModalInterviews()}
        open={session.candidate.unavailability_step === 'availability_task_2'}
      />
    </>
  )
}

export default HomeCandidate
