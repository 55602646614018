export const scoreLevels = {
  0: 'Iniciante',
  1: 'Intermediário(a)',
  2: 'Avançado(a)',
  3: 'Campeão(ã)',
}

export const scoreStepLevels = (healthscore) => {
  if (healthscore <= 49) return 0
  if (healthscore >= 50 && healthscore < 75) return 1
  if (healthscore >= 75 && healthscore < 100) return 2
  if (healthscore >= 100) return 3
}
